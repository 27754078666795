<template>
  <div style="height: 100%">
    <div style="height: calc(100% - 50px); position: relative">
      <headerr :customBack="true" @back="back" class="_header">
        Pazienti in sospeso
      </headerr>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    back() {
      if (this.$route.params.id) {
        this.$router.push("/pazienti");
      } else {
        this.$router.push("/home");
      }
    }
  },
  computed: {},
  created() {
    this.$store.dispatch("pazienti/recuperaPazienti");
  }
};
</script>

<style scoped></style>
